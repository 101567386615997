import { getLinksFromPaths } from './getLinksFromPaths';

/**
 * Relative application paths
 */
export const Paths = {
  index: '/',
  error: '500',
  notFound: '404',
  auth: {
    index: 'auth',
    login: 'login',
    loginUnprotected: 'login-unprotected',
    register: 'register',
    registerUnprotected: 'register-unprotected',
    adminLogin: 'admin-login',
    resetPassword: 'reset-password',
    resetPasswordConfirm: 'reset-password-confirm',
    verify: 'verify',
    activate: 'activate',
  },
  guest: {
    index: 'guest',
    reports: 'reports/:reportId',
    fillSurvey: 'fill-survey/:surveyId',
    survey: 'survey/:attackId',
  },
  dashboard: {
    index: 'dashboard',
    app: 'app',
    pastAttacks: 'past_attacks',
    ongoingAttacks: 'ongoing_attacks',
    surveys: 'surveys',
    fillSurvey: {
      index: 'fill-survey',
      surveyId: ':surveyId',
      attackId: '2/:attackId',
    },
    reports: {
      index: 'reports',
      reportId: ':reportId',
    },
    groups: {
      index: 'groups',
      list: 'list',
      create: 'create',
      edit: 'edit/:groupId',
      view: 'view/:groupId',
    },
    user: {
      index: 'user',
      account: {
        index: 'account',
        settings: 'settings',
        mfa: 'mfa',
      },
    },
    company: {
      index: 'company',
      list: 'list',
      employees: 'employees',
      variables: 'variables',
    },
    education: {
      index: 'education',
      content: {
        index: 'content',
        contentId: ':contentId',
      },
      upload: 'upload',
      edit: 'edit/:contentId',
      view: {
        index: 'view',
        contentId: ':contentId',
        submission: 'submission/:submissionId',
      },
      publish: 'publish/:contentId',
      quiz: 'quiz/create',
    },
  },
  admin: {
    index: 'admin',
    dashboard: {
      index: 'dashboard',
      customers: {
        index: 'customers',
        new: 'new',
        active: 'active',
        past: 'past',
        test: 'test',
      },
      domains: 'domains',
      settings: 'settings',
      mfa: 'mfa',
      attacks: {
        index: 'attacks',
        browse: 'browse',
        execute: {
          index: 'execute',
          id: ':id',
        },
        executeRetry: 'execute-retry/:id',
        create: 'create',
        executions: {
          index: 'executions',
          executionId: ':executionId',
          executionIdAttacks: ':executionId/attacks',
        },
      },
    },
  },
} as const;

export type PathsType = typeof Paths;

/**
 * Absolute application paths
 */
export const Links = getLinksFromPaths(Paths);

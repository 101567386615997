import { Helmet } from "react-helmet-async";
import { forwardRef, ReactNode } from "react";
// material
import { Box } from "@mui/material";
// utils

interface PageProps {
  title?: string;
  children: ReactNode;
}

const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = "", ...other }, ref) => {
    return (
      <Box ref={ref} {...other}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </Box>
    );
  }
);

Page.displayName = "Page";

export default Page;

import { User, UserRole } from 'api';

type ActionMap<M extends { [index: string]: unknown }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  Initialize = 'INITIALIZE',
  PickRole = 'PICK_ROLE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  Update = 'UPDATE',
  Spectate = 'SPECTATE',
}

type AuthPayload = {
  [Types.Initialize]: {
    user: User | null;
    spectator: boolean;
    isAuthenticated: boolean;
    selectedRole: UserRole | null;
  };
  [Types.PickRole]: {
    role: UserRole;
  };
  [Types.Login]: {
    user: User;
    refresh: boolean;
    spectator: boolean;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: User;
  };
  [Types.Update]: {
    user: Partial<User>;
  };
  [Types.Spectate]: {
    user: User | null;
    selectedRole: UserRole | null;
    spectator: boolean;
  };
};

export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];

export interface AuthProviderState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  gotInfo: boolean;
  user: User | null;
  spectator: boolean;
  spectatorRole: UserRole | null;
  selectedRole: UserRole | null;
}

export const authReducer = (state: AuthProviderState, action: AuthActions): AuthProviderState => {
  switch (action.type) {
    case Types.Initialize: {
      const { isAuthenticated, user, selectedRole, spectator } = action.payload;

      return {
        ...state,
        spectator: spectator,
        isAuthenticated,
        isInitialized: true,
        gotInfo: true,
        selectedRole,
        user,
      };
    }
    case Types.PickRole: {
      const { role } = action.payload;

      return {
        ...state,
        selectedRole: role,
      };
    }
    case Types.Login: {
      const { user, refresh = false, spectator } = action.payload;

      const selectedRole = refresh
        ? user.roles.find((role) => role.id === state.selectedRole?.id) || null
        : null;

      return {
        ...state,
        isAuthenticated: true,
        selectedRole,
        spectator: spectator,
        user,
      };
    }
    case Types.Logout: {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        selectedRole: null,
        spectator: false,
      };
    }
    case Types.Register: {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case Types.Update: {
      const { user } = action.payload;

      if (state.user) {
        return {
          ...state,
          user: {
            ...state.user,
            ...user,
          },
        };
      }
      return state;
    }
    case Types.Spectate: {
      const { user, spectator } = action.payload;
      return {
        ...state,
        spectator: spectator,
        isAuthenticated: true,
        user: user,
        selectedRole: null,
      };
    }
    default:
      return state;
  }
};

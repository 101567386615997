import { Form, FormikProvider } from "formik";
// material
import {
  Stack,
  Alert,
  Checkbox,
  TextField,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// hooks
import useLoginFormHandlers, {
  AfterSubmitErrors,
  LoginFormProps,
} from "./useLoginFormHandlers";
import { PasswordField } from "pages/authentication/components/passwordField";

export default function LoginForm({
  handleLogin,
  needOTP,
  mfaMethod,
}: LoginFormProps) {
  const { formik, translate } = useLoginFormHandlers({
    handleLogin,
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit ? (
            <Alert severity="error">
              {translate(errors.afterSubmit as AfterSubmitErrors)}
            </Alert>
          ) : null}

          {needOTP && (
            <Alert severity="warning">{translate("login.tfa_required")}</Alert>
          )}

          {needOTP && mfaMethod === "SMS" && (
            <Alert severity="info">{translate("login.sms_sent")}</Alert>
          )}

          {needOTP && mfaMethod === "EMAIL" && (
            <Alert severity="info">{translate("login.email_sent")}</Alert>
          )}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={translate("dashboard.user.tabs.general.email")}
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <PasswordField
            name="password"
            autoComplete="current-password"
            label={translate("dashboard.user.tabs.security.password")}
          />

          {needOTP ? (
            <TextField
              fullWidth
              autoComplete="off"
              label={translate(
                mfaMethod !== "OTF" ? "login.one_time_code" : "login.otp"
              )}
              {...getFieldProps("otp")}
              error={Boolean(touched.otp && errors.otp)}
              helperText={touched.otp && errors.otp}
            />
          ) : null}
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps("remember")}
                checked={values.remember}
              />
            }
            label={translate("login.remember_me")}
          />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {translate("login.login")}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
  AttackPreviewParams,
  AttackPreviewResponse,
  AttackStats,
  CampaignAttackRequestParams,
  ExportOperationParams,
  ExportStatus,
  PastAttacksResponse,
  SerializedDatePeriod,
} from "api";
import { useEffect, useState } from "react";

interface StatsRequestParams {
  period: SerializedDatePeriod | null;
  groupId?: string;
}

export interface ExportAttacksParams {
  campaign_id: string;
  company_id: number;
}

export interface ExportAttacksResponse {
  company_id: number;
  error?: string;
  file_link?: string;
  id: string;
  status: ExportStatus;
}
export const attacksApi = createApi({
  reducerPath: "attacksApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/attacks/" }),
  tagTypes: ["pastAttacks"],
  endpoints: (builder) => ({
    stats: builder.query<AttackStats, StatsRequestParams>({
      query: ({ period, groupId }) => ({
        url: "stats",
        method: "POST",
        body: { period, group_id: groupId },
      }),
    }),

    getWeakEmployees: builder.query({
      query: ({ period, startIndex, endIndex }) => ({
        url: "get_weak_employees",
        method: "POST",
        body: {
          period,
          start_index: startIndex,
          end_index: endIndex,
        },
      }),
    }),

    pastAttacks: builder.query<PastAttacksResponse, unknown>({
      query: ({
        period,
        startIndex,
        endIndex,
        filters,
        sorting,
        timezoneOffset,
      }) => ({
        url: "past_attacks",
        method: "POST",
        body: {
          period,
          start_index: startIndex,
          end_index: endIndex,
          filters,
          sorting,
          timezone_offset: timezoneOffset,
        },
      }),
      providesTags: ["pastAttacks"],
    }),

    exportPastAttacks: builder.query({
      query: ({ period, companyId, filters }) => ({
        url: "export_past_attacks",
        method: "POST",
        body: {
          period,
          company_id: companyId,
          filters,
        },
      }),
    }),
    generateAttackInfoPreview: builder.query<
      AttackPreviewResponse,
      AttackPreviewParams
    >({
      query: (body) => ({
        url: "generate_attack_info_preview",
        method: "POST",
        body,
      }),
    }),
    generateAttackPreview: builder.query<AttackPreviewResponse, { id: string }>(
      {
        query: ({ id }) => ({
          url: "generate_attack_preview",
          method: "GET",
          params: { id },
        }),
      }
    ),
    triggerExportAttacks: builder.mutation<
      ExportAttacksResponse,
      CampaignAttackRequestParams
    >({
      query: (body) => ({
        url: "export",
        method: "POST",
        body: {
          campaign_id: body.campaignId,
        },
      }),
    }),
    exportAttacks: builder.query<ExportAttacksResponse, ExportOperationParams>({
      query: ({ id }) => ({
        url: `export/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useStatsQuery,
  useGetWeakEmployeesQuery,
  usePastAttacksQuery,
  useExportPastAttacksQuery,
  useGenerateAttackInfoPreviewQuery,
  useGenerateAttackPreviewQuery,
  useExportAttacksQuery,
  useTriggerExportAttacksMutation,
} = attacksApi;

type PollingReturn = ReturnType<typeof useExportAttacksQuery> &
  ReturnType<typeof useExportAttacksQuery>["isError"];

export function usePollingExportAttacksQuery(
  params: Parameters<typeof useExportAttacksQuery>[0],
  options: Parameters<typeof useExportAttacksQuery>[1] = {}
): PollingReturn {
  const [shouldPoll, setShouldPoll] = useState(true);
  const query = useExportAttacksQuery(params, {
    pollingInterval: shouldPoll ? 5000 : undefined,
    ...options,
  });

  useEffect(() => {
    const isExportResolved = ["DONE", "ERROR"].includes(query.data?.status);
    const isRequestError = query.error;

    if (isExportResolved || isRequestError) {
      setShouldPoll(false);
    }
  }, [query.data?.status, query.error, setShouldPoll]);

  return query;
}

import { createContext, useReducer } from 'react';

const initialState = {
  companyUrl: null,
  nextPage: null,
  loadingText: null,
  setNextPage: null,
};

export const DemoContext = createContext(initialState);

function demoReducer(state, action) {
  switch (action.type) {
    case 'SET_COMPANY_URL':
      return {
        ...state,
        companyUrl: action.payload,
      };

    case 'SET_NEXT_PAGE':
      console.log(action.payload);
      return {
        ...state,
        nextPage: action.payload.page,
        loadingText: action.payload.text,
      };

    default:
      return state;
  }
}

export default function DemoProvider(props) {
  const [state, dispatch] = useReducer(demoReducer, initialState);

  const setNextPage = (payload) => {
    dispatch({ type: 'SET_NEXT_PAGE', payload });
  };

  const setCompanyUrl = (payload) => {
    dispatch({ type: 'SET_COMPANY_URL', payload });
  };

  return (
    <DemoContext.Provider
      value={{
        ...state,
        setNextPage,
        setCompanyUrl,
      }}
    >
      {props.children}
    </DemoContext.Provider>
  );
}

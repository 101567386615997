import PropTypes from 'prop-types';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <Box
      sx={{
        width: 40,
        height: 40,
        backgroundImage: 'url("/static/brand/logo_small_white.png")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: 'center',
        ...sx,
      }}
    ></Box>
  );
}

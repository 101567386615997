import { useContext } from 'react';

import { AuthContext } from 'contexts/OldLoginContext';

export default function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within its Provider`);
  }
  return context;
}

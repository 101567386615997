import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
  AttackTargetsRequestParams,
  AttackTargetsResponse,
  CampaignAttackRequestParams,
  CampaignAttackResponse,
  CompanyExecutionTargetResponse,
  CompanyInfoResponse,
  Customer,
  CustomerKind,
  CustomersCount,
  EndSocialAttackRequestParams,
  ManageNewCustomerParams,
  NewCustomer,
  RemoveSocialAttackRequestParams,
  ScenarioListParams,
  ScenariosListResponseSuccess,
} from "../../api";

// TODO: add all admin-related api calls here

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/admin/" }),
  tagTypes: [
    "demoAttackList",
    "listAttackInfos",
    "customersList",
    "listNewCustomers",
    "customersCount",
    "companyBasicInfo",
    "pastAttacks",
  ],
  endpoints: (builder) => ({
    deactivateCustomer: builder.mutation<
      Record<string, unknown>,
      { customerId: string }
    >({
      query: ({ customerId }) => ({
        url: `/company/${customerId}/deactivate`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["listNewCustomers", "customersList", "customersCount"],
    }),

    restoreCustomer: builder.mutation<
      Record<string, unknown>,
      { customerId: string }
    >({
      query: ({ customerId }) => ({
        url: `/company/${customerId}/restore`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["listNewCustomers", "customersList", "customersCount"],
    }),
    updateCompanyBasicInfo: builder.mutation<
      CompanyInfoResponse,
      { customerId: string; employeeLimit: number; companyName: string }
    >({
      query: ({ customerId, employeeLimit, companyName }) => ({
        url: `company/${customerId}`,
        method: "PATCH",
        body: {
          employee_limit: employeeLimit,
          name: companyName,
        },
      }),
      invalidatesTags: [
        "listNewCustomers",
        "customersList",
        "companyBasicInfo",
      ],
    }),

    getCompanyBasicInfo: builder.query<
      CompanyInfoResponse,
      { customerId: string }
    >({
      query: ({ customerId }) => ({
        url: `company/${customerId}`,
        method: "GET",
      }),
      providesTags: ["companyBasicInfo"],
    }),

    pollAttackExecution: builder.query({
      query: ({ pollToken }) => ({
        url: "poll_attack_execution",
        method: "GET",
        params: {
          poll_token: pollToken,
        },
      }),
    }),

    listDemoAttacks: builder.query({
      query: ({ companyUrl, includeContent }) => ({
        url: "list_demo_attacks",
        method: "GET",
        params: {
          company_url: companyUrl,
          include_content: includeContent,
        },
      }),
      providesTags: ["demoAttackList"],
    }),

    getAttackInfo: builder.query({
      query: ({ id, include_content }) => ({
        url: "get_attack_info",
        method: "GET",
        params: {
          id,
          include_content,
        },
      }),
    }),

    handleShowAttackInfo: builder.mutation({
      query: ({ id, isHidden }) => ({
        url: `/attack_info/${id}`,
        method: "PATCH",
        body: {
          is_hidden: isHidden,
        },
      }),
      invalidatesTags: ["listAttackInfos"],
    }),

    getExecutionTargets: builder.query<
      CompanyExecutionTargetResponse,
      { id: string; offset: number; limit: number }
    >({
      query: ({ id, offset, limit }) => ({
        url: `/campaign/${id}/company_attack`,
        method: "GET",
        params: {
          offset,
          limit,
        },
      }),
    }),
    deleteDemoAttack: builder.mutation({
      query: ({ attackInfoId }) => ({
        url: "delete_demo_attack",
        method: "POST",
        body: {
          attack_info_id: attackInfoId,
        },
      }),
      invalidatesTags: ["demoAttackList"],
    }),
    saveAttackInfoContent: builder.mutation({
      query: (body) => ({
        url: "save_attack_info_content",
        method: "POST",
        body,
      }),
      invalidatesTags: ["listAttackInfos"],
    }),
    addAttackInfo: builder.mutation({
      query: (body) => ({
        url: "add_attack_info",
        method: "POST",
        body,
      }),
      invalidatesTags: ["listAttackInfos"],
    }),
    listAttackInfos: builder.query<
      ScenariosListResponseSuccess,
      ScenarioListParams
    >({
      query: (body) => ({
        url: "list_attack_infos",
        method: "POST",
        body,
      }),
      providesTags: ["listAttackInfos"],
    }),
    addMissingCompanyVariables: builder.mutation({
      query: (body) => ({
        url: "add_missing_company_variables",
        method: "POST",
        body,
      }),
    }),
    updateAttackInfoTags: builder.mutation({
      query: (body) => ({
        url: "update_attack_info_tags",
        method: "POST",
        body,
      }),
      invalidatesTags: ["listAttackInfos"],
    }),
    deleteAttackInfo: builder.mutation({
      query: (body) => ({
        url: "delete_attack_info",
        method: "POST",
        body,
      }),
      invalidatesTags: ["listAttackInfos"],
    }),
    finishDraft: builder.mutation({
      query: (body) => ({
        url: "finish_draft",
        method: "POST",
        body,
      }),
      invalidatesTags: ["listAttackInfos"],
    }),
    listCustomers: builder.query<
      { items: Customer[] },
      { type?: CustomerKind }
    >({
      providesTags: ["customersList"],
      query: ({ type } = {}) => ({
        url: "company",
        method: "GET",
        params: {
          type,
        },
      }),
    }),
    listNewCustomers: builder.query<{ customers: NewCustomer[] }, unknown>({
      providesTags: ["listNewCustomers"],
      query: () => ({
        url: "new_customers",
        method: "GET",
      }),
    }),
    manageNewCustomer: builder.mutation<unknown, ManageNewCustomerParams>({
      query: (body) => ({
        url: "/handle_new_customer",
        method: "POST",
        body,
      }),
      invalidatesTags: ["listNewCustomers", "customersCount"],
    }),
    getCustomersCount: builder.query<CustomersCount, unknown>({
      providesTags: ["customersCount"],
      query: () => ({
        url: "company_count",
        method: "GET",
      }),
    }),
    determineAttackTargets: builder.query<
      AttackTargetsResponse,
      AttackTargetsRequestParams
    >({
      query: (body) => ({
        url: "determine_attack_targets",
        method: "POST",
        body,
      }),
    }),
    endSocialAttack: builder.mutation<unknown, EndSocialAttackRequestParams>({
      query: (body) => ({
        url: "end_social_attack",
        method: "POST",
        body,
      }),
      invalidatesTags: ["pastAttacks"],
    }),
    removeSocialAttack: builder.mutation<
      unknown,
      RemoveSocialAttackRequestParams
    >({
      query: (body) => ({
        url: "remove_social_attack",
        method: "POST",
        body,
      }),
      invalidatesTags: ["pastAttacks"],
    }),
    campaignAttack: builder.query<
      CampaignAttackResponse,
      CampaignAttackRequestParams
    >({
      query: ({ campaignId, limit, offset }) => ({
        url: `campaign/${campaignId}/attack`,
        method: "GET",
        params: {
          limit,
          offset,
        },
      }),
    }),
  }),
});

export const {
  useListCustomersQuery,
  useListAttackInfosQuery,
  useListNewCustomersQuery,
  useGetCompanyBasicInfoQuery,
  useGetCustomersCountQuery,
  useManageNewCustomerMutation,
  useDeactivateCustomerMutation,
  useRestoreCustomerMutation,
  useUpdateCompanyBasicInfoMutation,
  useEndSocialAttackMutation,
  useRemoveSocialAttackMutation,
  useCampaignAttackQuery,
} = adminApi;

import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DemoLayout from "../layouts/demo";
import DemoSelectLayout from "../layouts/demo-select";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// components
import DemoLoadingLayout from "../layouts/demo-loading";
import Loadable from "../utils/Loadable";

import { Links, Paths } from "./pathsv2";
import AuthGuard from "../guards/AuthGuard";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: Paths.error, element: <Page500 /> },
        { path: Paths.notFound, element: <NotFound /> },
        { path: "*", element: <Navigate to={Links.notFound} replace /> },
      ],
    },

    // Demo Routes
    {
      path: "/",
      element: (
        <AuthGuard>
          <DemoSelectLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Navigate to="/portal" replace /> },
        { path: "portal", element: <DemoSelect /> },
      ],
    },

    {
      path: "analyze",
      element: <DemoLoadingLayout />,
      children: [
        { path: "", element: <Navigate to="/analyze/attacks" replace /> },
        { path: "attacks", element: <DemoLoading /> },
      ],
    },

    {
      path: "ai",
      element: <DemoLayout />,
      children: [
        { path: "", element: <Navigate to="/portal" replace /> },
        { path: "select", element: <DemoSelect /> },
        { path: "info", element: <DemoInfo /> },
        {
          path: "go-events",
          element: <DemoLoading small redirectTo="/demo/events" wait={2000} />,
        },
        { path: "events", element: <DemoEvents /> },
        {
          path: "go-attacks",
          element: <DemoLoading small redirectTo="/demo/attacks" wait={2000} />,
        },
        { path: "attacks", element: <DemoAttacks /> },
        { path: "loading", element: <DemoLoading small /> },
      ],
    },

    { path: "*", element: <Navigate to={Links.notFound} replace /> },
  ]);
}

// IMPORT COMPONENTS

// Demo
const DemoInfo = Loadable(lazy(() => import("../pages/demo/Info")));
const DemoLoading = Loadable(lazy(() => import("../pages/demo/Loading")));
const DemoSelect = Loadable(lazy(() => import("../pages/demo/Select")));
const DemoEvents = Loadable(lazy(() => import("../pages/demo/Events")));
const DemoAttacks = Loadable(lazy(() => import("../pages/demo/Attacks")));

// Main
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));

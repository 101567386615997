import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";

import { rootPersistConfig, rootReducer } from "redux/rootReducer";
import { authApi } from "redux/services/auth";
import { attacksApi } from "redux/services/attacks";
import { adminApi } from "./services/admin";

export const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),

  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }).concat(authApi.middleware, attacksApi.middleware, adminApi.middleware),
});

export const persistor = persistStore(store);
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch = () => useReduxDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

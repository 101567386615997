import { styled } from "@mui/material/styles";
import { CircularProgress, Container } from "@mui/material";
import Page from "../Page";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import SuccessFail from "components/_login/SuccessFail";
import { useLocation } from "react-router-dom";
import AuthLayout from "../../layouts/AuthLayout";
import { LoginSetupContext } from "./LoginSetupContext";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

//-----------------------------------------------------------------------

// ----------------------------------------------------------------------

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface LoginContainerProps {
  title?: string;
  done?: boolean;
  prefix?: string;
  setup?: (query: URLSearchParams) => Promise<{
    token: string | null;
  }>;
  authComponent?: ReactElement;
  rootComponent?: ReactElement;
  children?: ReactNode;
  isAdmin?: boolean;
}

export default function LoginContainer({
  title,
  prefix,
  setup,
  authComponent,
  rootComponent,
  children,
  isAdmin = false,
  done: doneExternal = false,
}: LoginContainerProps) {
  const [loading, setLoading] = useState(true);
  const [done, setDone] = useState(doneExternal);
  const [error, setError] = useState(false);
  const [setupValue, setSetupValue] = useState({});
  const query = useQuery();

  useEffect(() => {
    (async () => {
      try {
        if (setup) {
          setSetupValue(await setup(query));
        }
      } catch (e) {
        setError(true);
        setDone(true);
      }

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle title={title}>
      {rootComponent}

      <AuthLayout>{authComponent}</AuthLayout>

      {/*<MHidden width="mdDown">{isAdmin ? <AdminSectionStyle /> : <SectionStyle />}</MHidden>*/}

      <Container>
        <ContentStyle>
          {loading && <CircularProgress size={150} />}

          {!loading && (
            <LoginSetupContext.Provider value={{ ...setupValue, setDone }}>
              {done ? <SuccessFail prefix={prefix} error={error} /> : children}
            </LoginSetupContext.Provider>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

import { ReactElement, ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from 'redux/store';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { ThemeConfig } from 'theme';

interface ContextProvidersProps {
  children: ReactNode;
}

export function ContextProvidersWithoutAuth({ children }: ContextProvidersProps): ReactElement {
  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeConfig>{children}</ThemeConfig>
        </LocalizationProvider>
      </ReduxProvider>
    </HelmetProvider>
  );
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
  InvitationValidateParams,
  InvitationValidateResponse,
  InvitationAcceptParams,
  InvitationAcceptSSOParams,
  LoginRequestParams,
  LoginResponse,
  LoginSSORequestParams,
  RegisterRequestParams,
  SSORegisterRequestParams,
  SingUpEmailValidateParams,
} from "api";

export interface VildateRegisterSSOResponse {
  first_name: string | null;
  last_name: string | null;
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/auth/" }),
  tagTypes: ["socialLinks", "mfa"],
  endpoints: (builder) => ({
    socialLinks: builder.query({
      query: () => ({
        url: "social_links",
      }),
      providesTags: ["socialLinks"],
    }),

    updateSocialLinks: builder.mutation({
      query: (body) => ({
        url: "update_social_links",
        method: "POST",
        body,
      }),
      invalidatesTags: ["socialLinks"],
    }),

    mfaStatus: builder.query({
      query: () => ({
        url: "mfa/status",
      }),
      providesTags: ["mfa"],
    }),

    mfaDisable: builder.mutation({
      query: () => ({
        url: "mfa/disable",
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["mfa"],
    }),

    mfaFinish: builder.mutation({
      query: ({ otp }) => ({
        url: "mfa/finish",
        method: "POST",
        body: { otp },
      }),
      invalidatesTags: ["mfa"],
    }),

    mfaSendSMS: builder.mutation({
      query: ({ phone }) => ({
        url: "mfa/send_sms",
        method: "POST",
        body: { phone },
      }),
    }),

    mfaSendEmail: builder.mutation({
      query: () => ({
        url: "mfa/send_email",
        method: "POST",
      }),
    }),

    mfaValidate: builder.mutation({
      query: ({ method, code }) => ({
        url: "mfa/validate",
        method: "POST",
        body: { method, code },
      }),
    }),
    singUpEmailValidate: builder.mutation<void, SingUpEmailValidateParams>({
      query: ({ email }) => ({
        url: "validate_register",
        method: "POST",
        body: { email },
      }),
    }),
    validateRegisterSSO: builder.mutation<
      VildateRegisterSSOResponse,
      Pick<SSORegisterRequestParams, "kind" | "token">
    >({
      query: ({ kind, token }) => ({
        url: "validate_register_sso",
        method: "POST",
        body: { kind, token },
      }),
    }),
    registerSSO: builder.mutation<unknown, SSORegisterRequestParams>({
      query: (body) => ({
        url: "register_sso",
        method: "POST",
        body,
      }),
    }),
    register: builder.mutation<unknown, RegisterRequestParams>({
      query: (body) => ({
        url: "register",
        method: "POST",
        body,
      }),
    }),
    login: builder.mutation<LoginResponse, LoginRequestParams>({
      query: (body) => ({
        url: "login",
        method: "POST",
        body,
      }),
    }),
    loginSSO: builder.mutation<LoginResponse, LoginSSORequestParams>({
      query: (body) => ({
        url: "login_sso",
        method: "POST",
        body,
      }),
    }),
    customerInvitationValidate: builder.query<
      InvitationValidateResponse,
      InvitationValidateParams
    >({
      query: ({ invitationId }) => ({
        url: `customer_invitation/${invitationId}`,
        method: "GET",
      }),
    }),
    customerInvitationAccept: builder.mutation<void, InvitationAcceptParams>({
      query: ({ invitationId, ...body }) => ({
        url: `customer_invitation/${invitationId}/accept`,
        method: "POST",
        body,
      }),
    }),
    customerInvitationAcceptSSO: builder.mutation<
      void,
      InvitationAcceptSSOParams
    >({
      query: ({ invitationId, ...body }) => ({
        url: `customer_invitation/${invitationId}/accept_sso`,
        method: "POST",
        body,
      }),
    }),
    resellerInvitationValidate: builder.query<
      InvitationValidateResponse,
      InvitationValidateParams
    >({
      query: ({ invitationId }) => ({
        url: `reseller_invitation/${invitationId}`,
        method: "GET",
      }),
    }),
    resellerInvitationAccept: builder.mutation<void, InvitationAcceptParams>({
      query: ({ invitationId, ...body }) => ({
        url: `reseller_invitation/${invitationId}/accept`,
        method: "POST",
        body,
      }),
    }),
    resellerInvitationAcceptSSO: builder.mutation<
      void,
      InvitationAcceptSSOParams
    >({
      query: ({ invitationId, ...body }) => ({
        url: `reseller_invitation/${invitationId}/accept_sso`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useSingUpEmailValidateMutation,
  useRegisterSSOMutation,
  useCustomerInvitationValidateQuery,
  useCustomerInvitationAcceptMutation,
  useCustomerInvitationAcceptSSOMutation,
  useResellerInvitationValidateQuery,
  useResellerInvitationAcceptMutation,
  useResellerInvitationAcceptSSOMutation,
} = authApi;

import useAuth from 'hooks/useAuth';
import { useRedirectToDashboardByRoleCallback } from 'pages/authentication/hooks';
import { ListItemButton, Typography } from '@mui/material';

export function RoleItem({ role }: { role: any }) {
  const { pickRole } = useAuth();
  const redirectToDashboardByRole = useRedirectToDashboardByRoleCallback();

  const handleClick = () => {
    pickRole(role);

    redirectToDashboardByRole(role);
  };

  return (
    <ListItemButton
      onClick={handleClick}
      sx={{
        paddingLeft: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Typography>Login</Typography>
      <Typography fontWeight="bold">role</Typography>
    </ListItemButton>
  );
}

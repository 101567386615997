import { Box, Card, CircularProgress, Divider, List, Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useRedirectToDashboardByRoleCallback } from 'pages/authentication/hooks';
import { RoleItem } from 'pages/authentication/components/PickRole/RoleItem';

export function PickRole() {
  const { user, selectedRole, pickRole } = useAuth();
  const [loading, setLoading] = useState(true);
  const redirectToDashboardByRole = useRedirectToDashboardByRoleCallback();

  useEffect(() => {
    if (!selectedRole && user?.roles?.length === 1) {
      // there is only one option, choose only role
      const maybeRole = user.roles.at(0);
      if (maybeRole) {
        pickRole(maybeRole);
        redirectToDashboardByRole(maybeRole);
      }

      return;
    }

    if (loading) setLoading(false);
  }, [loading, pickRole, redirectToDashboardByRole, selectedRole, user?.roles]);

  if (loading) {
    return <CircularProgress sx={{ display: 'table', mx: 'auto' }} />;
  }

  return (
    <Card
      sx={{
        p: 3,
        boxShadow: 'none',
      }}
    >
      <Box>
        <Typography variant="h4" gutterBottom>
          Pick a role
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>this is a role</Typography>
      </Box>

      <Divider sx={{ my: 4 }} />

      <List>{user?.roles?.map?.((role, i) => <RoleItem key={i} role={role} />)}</List>
    </Card>
  );
}

import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useReducer } from 'react';
import { useField } from 'formik';

interface PasswordFieldProps {
  name: string;
  autoComplete: 'current-password' | 'off';
  label: string;
}

export default function PasswordField({ name, autoComplete, label }: PasswordFieldProps) {
  const [showPassword, handleShowPassword] = useReducer((state) => !state, false);
  const [field, meta] = useField(name);

  return (
    <TextField
      fullWidth
      autoComplete={autoComplete}
      type={showPassword ? 'text' : 'password'}
      label={label}
      {...field}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowPassword} edge="end">
              <Icon icon={showPassword ? eyeFill : eyeOffFill} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
}

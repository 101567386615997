import { useNavigate } from 'react-router-dom';
import { Links } from 'routes/pathsv2';
import { useCallback } from 'react';
// import { Role } from 'redux/services/apis';

export const useRedirectToDashboardByRoleCallback = () => {
  const navigate = useNavigate();

  return useCallback(
    (_: any) => {
      navigate(Links.index);
    },
    [navigate],
  );
};

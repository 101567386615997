import { Done, Error } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useLocales } from "../../hooks/useLocales";
import { Link as RouterLink } from "react-router-dom";
import { Paths } from "../../routes/pathsv2";

export default function SuccessFail({ prefix, error }) {
  const { translate } = useLocales();
  const longPrefix = `${prefix}.${error ? "error" : "success"}`;
  const text = translate(`${longPrefix}.text`);

  return (
    <Box sx={{ textAlign: "center" }}>
      {!error ? (
        <Done color="success" sx={{ width: 160, height: 160 }} />
      ) : (
        <Error color="error" sx={{ width: 160, height: 160 }} />
      )}

      <Typography variant="h3" gutterBottom>
        {translate(`${longPrefix}.title`)}
      </Typography>
      {text && <Typography>{text}</Typography>}

      <Button
        size="large"
        variant="contained"
        component={RouterLink}
        to={Paths.auth.login}
        sx={{ mt: 5, minWidth: 100 }}
      >
        {translate("common.back")}
      </Button>
    </Box>
  );
}

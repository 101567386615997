import { createContext } from 'react';

export interface LoginSetupContextState {
  setDone: React.Dispatch<React.SetStateAction<boolean>>;
  token?: string;
}

const loginSetupContextInitialValue = {
  setDone: ((value: boolean) => {
    console.log(value);
  }) as React.Dispatch<React.SetStateAction<boolean>>,
};

export const LoginSetupContext = createContext<LoginSetupContextState>(
  loginSetupContextInitialValue,
);

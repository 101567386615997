import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
// slices
import attacksReducer from "./slices/attacks";
import adminReducer from "./slices/admin";
import { authApi } from "./services/auth";
import { attacksApi } from "./services/attacks";
import { adminApi } from "./services/admin";
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  attacks: attacksReducer,
  admin: adminReducer,
  [authApi.reducerPath]: authApi.reducer,
  [attacksApi.reducerPath]: attacksApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
});

export { rootPersistConfig, rootReducer };

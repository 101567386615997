import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: true,
  error: false,
  newCustomers: [],
  existingCustomers: [],
  attackInfos: [],
  domains: [],
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getNewCustomersSuccess(state, action) {
      state.isLoading = false;
      state.newCustomers = action.payload;
    },

    getExistingCustomersSuccess(state, action) {
      state.isLoading = false;
      state.existingCustomers = action.payload;
    },

    listAttackInfosSuccess(state, action) {
      state.isLoading = false;
      state.attackInfos = action.payload;
    },

    listDomainsSuccess(state, action) {
      state.isLoading = false;
      state.domains = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getNewCustomers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/admin/new_customers');
      dispatch(slice.actions.getNewCustomersSuccess(response.data.customers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getExistingCustomers(type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/admin/list_customers', {
        params: {
          type,
        },
      });
      dispatch(slice.actions.getExistingCustomersSuccess(response.data.customers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function listAttackInfos() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/admin/list_attack_infos');
      dispatch(slice.actions.listAttackInfosSuccess(response.data.infos));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function listDomains() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/admin/list_domains');
      dispatch(slice.actions.listDomainsSuccess(response.data.domains));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',

  '&:before': {
    content: "' '",
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    background: 'url("/static/demo/matrix-theme-5.jpg")',
    opacity: 0.03,
  },

  '&:after': {
    zIndex: 99,
    content: "' '",
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',

    background: 'url("/static/brand/logo_only_1024.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '50%',
    backgroundPositionY: '100%',
    opacity: 0.4,
  },
});

const MainStyle = styled('div')(({ theme }) => ({
  zIndex: 100,
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    // paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DemoSelectLayout() {
  return (
    <RootStyle>
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}

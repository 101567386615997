import {Outlet} from 'react-router-dom';
import {styled, useTheme} from '@mui/material/styles';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';

// ----------------------------------------------------------------------

// const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    // paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DemoLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();

  return (
    <RootStyle>
      {/*<DemoNavbar onOpenSidebar={() => setOpen(true)} />*/}
      {/*<DemoSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />*/}
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex,
          }),
          bgcolor: (() => {
            return theme.palette.mode === 'light' ? theme.palette.primary.lighter : undefined;
          })(),
          ...(collapseClick && {
            ml: '102px',
          }),
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}

import useIsMountedRef from "hooks/useIsMountedRef";
import { useLocales } from "hooks/useLocales";
import * as Yup from "yup";
import { useFormik } from "formik";
import { HandleLogin, LoginParams } from "../../types";

export interface LoginFormProps {
  handleLogin: HandleLogin;
  needOTP: boolean;
  mfaMethod?: string;
}

// TODO: specify all possible errors after loginForm submission
export type AfterSubmitErrors =
  | "login.incorrect_password_or_email"
  | "login.bad_credentials";

interface FormikValidatedFields extends LoginParams {
  afterSubmit?: AfterSubmitErrors;
}

export default function useLoginFormHandlers({
  handleLogin,
}: Pick<LoginFormProps, "handleLogin">) {
  const isMountedRef = useIsMountedRef();
  const { translate } = useLocales();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(translate("login.invalid_email"))
      .required(translate("common.field_required")),
    password: Yup.string().required(translate("common.field_required")),
    otp: Yup.string(),
  });

  const formik = useFormik<FormikValidatedFields>({
    initialValues: {
      email: "",
      password: "",
      otp: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
      handleLogin(values)
        .then(() => {
          if (isMountedRef.current) {
            setSubmitting(false);
          }
        })
        .catch((error) => {
          console.error(error);
          resetForm();
          if (isMountedRef.current) {
            setSubmitting(false);
            const message =
              error instanceof Error ? error.message : String(error);
            setErrors({ afterSubmit: message });
          }
        });
    },
  });

  return {
    formik,
    translate,
  };
}

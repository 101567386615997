import { useEffect, useState, Dispatch } from "react";

import {
  ErrorResponse,
  UpdateGeneralUserParams,
  UserRole,
  RegisterValues,
  LoginResponse,
  LoginResponseState,
  UserInfo,
} from "api";
import { useLocales } from "hooks";
import axios from "utils/axios";
import { AuthContextHandlers } from ".";

import { AuthActions, Types } from "./reducer";
import { USER_ROLE_KEY } from "settings";
import useSettings from "../../hooks/useSettings";
import { authApi } from "../../redux/services/auth";

export interface SignInResult {
  state: LoginResponseState;
  mfaMethod?: string;
  message?: string;
}

export function useAuthInitialize(dispatch: Dispatch<AuthActions>): void {
  const [initialized, setInitialized] = useState(false);

  const isGuest = window.location.pathname.startsWith("/guest/");

  useEffect(() => {
    if (initialized || isGuest) return;

    const initialize = async () => {
      try {
        const { data } = await axios.get<UserInfo>("/api/auth/info");

        const preselectedUserRoleName = localStorage.getItem(USER_ROLE_KEY);

        if (data !== undefined) {
          const role = data.user.roles.find(
            (role: UserRole) => role.name === preselectedUserRoleName
          );

          dispatch({
            type: Types.Initialize,
            payload: {
              spectator: data.spectator,
              isAuthenticated: true,
              user: data.user,
              selectedRole: data?.role_id ? (role ? role : null) : null,
            },
          });
          return;
        }
      } catch (e) {
        console.error(e);
      }

      dispatch({
        type: Types.Initialize,
        payload: {
          spectator: false,
          isAuthenticated: false,
          user: null,
          selectedRole: null,
        },
      });
    };

    initialize();
    setInitialized(true);
  }, [dispatch, isGuest, initialized]);
}

export function useAuthHandlers(
  dispatch: Dispatch<AuthActions>
): AuthContextHandlers {
  const { translate } = useLocales();
  const { onChangeModeColor, manualOverride } = useSettings();
  const [registerUser] = authApi.endpoints.register.useMutation();
  const [loginUser] = authApi.endpoints.login.useMutation();

  async function spectate(company_id: string | null): Promise<void> {
    const response = await axios.post("/api/admin/spectate", { company_id });
    dispatch({
      type: Types.Spectate,
      payload: {
        user: response.data.user,
        selectedRole: null,
        spectator: response.data.spectator,
      },
    });
  }

  async function pickRole(role: UserRole): Promise<void> {
    await axios.post("/api/auth/pick_role", {
      role_id: role.id,
    });

    dispatch({
      type: Types.PickRole,
      payload: {
        role,
      },
    });

    localStorage.setItem(USER_ROLE_KEY, role.name);
  }

  async function refresh(refresh = true): Promise<void> {
    const { data } = await axios.get<UserInfo>("/api/auth/info");

    dispatch({
      type: Types.Login,
      payload: {
        user: data.user,
        refresh,
        spectator: data.spectator,
      },
    });
  }

  async function handleLoginResponse(
    data: LoginResponse,
    email?: string,
    sso = false
  ): Promise<SignInResult> {
    try {
      switch (data.response_state) {
        case "ok":
          if (!manualOverride && email?.endsWith?.("@hamagin.co.jp")) {
            onChangeModeColor("light", "cyan");
          }
          break;
        case "two_factor_required":
          return {
            state: data.response_state,
            mfaMethod: data.mfa_method,
          };
        case "two_fa_setup_not_completed":
          break;
        case "deactivated":
          return {
            state: data.response_state,
            message: translate("login.deactivatedAccount"),
          };
        default:
          return {
            state: data.response_state,
            message: translate(
              `login.${data.response_state}` as "login.bad_credentials"
            ),
          };
      }

      await refresh(false);
      return { state: data.response_state };
    } catch (error) {
      return { state: "error" };
    }
  }

  async function login(
    email: string,
    password: string,
    remember: boolean,
    otp: string | null,
    isAdmin = false
  ): Promise<SignInResult> {
    const data = await loginUser({
      email,
      password,
      remember,
      otp: otp || "",
      admin: isAdmin,
    }).unwrap();

    return handleLoginResponse(data, email);
  }

  async function register(
    email: string,
    password: string,
    values: RegisterValues,
    referral: string | null
  ): Promise<void> {
    try {
      await registerUser({
        email,
        password,
        language: values.language,
        first_name: values.first_name,
        last_name: values.last_name,
        company_name: values.company_name,
        referral,
      }).unwrap();
    } catch (e) {
      const error = e as ErrorResponse;
      if (error?.Error) {
        switch (error.Error) {
          case "account_already_exists":
            throw new Error(error.Error);

          default:
            throw new Error("bad_request");
        }
      }
    }
  }

  async function resetPassword(email: string): Promise<void> {
    try {
      await axios.post("/api/auth/forgot_password", { email });
    } catch (e) {
      const error: ErrorResponse = e as ErrorResponse;
      if (error?.Error) {
        throw new Error(error.Error);
      }
    }
  }

  async function resetPasswordConfirm(
    token: string,
    password: string
  ): Promise<void> {
    try {
      await axios.post("/api/auth/change_password", {
        forgot_password_token: token,
        password,
      });
    } catch (e) {
      const error: ErrorResponse = e as ErrorResponse;
      if (error?.Error) {
        throw new Error(error.Error);
      }
    }
  }

  async function updateProfile(values: UpdateGeneralUserParams): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { email, ...withoutEmail } = values;
    await axios.post("/api/customer/edit_customer_details", withoutEmail);

    dispatch({
      type: Types.Update,
      payload: {
        user: {
          ...withoutEmail,
          firstName: withoutEmail.first_name,
          lastName: withoutEmail.last_name,
        },
      },
    });
  }

  async function updateProfileLocally(values: {
    has_otp: boolean;
  }): Promise<void> {
    dispatch({
      type: Types.Update,
      payload: {
        user: {
          ...values,
        },
      },
    });
  }

  async function logout(): Promise<void> {
    await axios.post("/api/auth/logout");
    dispatch({ type: Types.Logout });
  }

  return {
    spectate,
    pickRole,
    refresh,
    login,
    handleLoginResponse,
    register,
    resetPassword,
    resetPasswordConfirm,
    updateProfile,
    updateProfileLocally,
    logout,
  };
}

import { createContext, ReactNode, useReducer } from "react";

import {
  LoginData,
  RegisterValues,
  UpdateGeneralUserParams,
  UserRole,
} from "api";

import { AuthProviderState, authReducer } from "./reducer";
import { SignInResult, useAuthHandlers, useAuthInitialize } from "./hooks";

const initialState: AuthProviderState = {
  isAuthenticated: false,
  isInitialized: false,
  gotInfo: false,
  user: null,
  spectator: false,
  spectatorRole: null,
  selectedRole: null,
};

export interface AuthContextHandlers {
  register: (
    email: string,
    password: string,
    values: RegisterValues,
    referral: string | null
  ) => Promise<void>;
  login: (
    email: string,
    password: string,
    remember: boolean,
    otp: string | null,
    isAdmin?: boolean
  ) => Promise<SignInResult>;
  handleLoginResponse: (
    data: LoginData,
    email?: string,
    sso?: boolean
  ) => Promise<SignInResult>;
  logout: () => Promise<void>;
  pickRole: (role: UserRole) => Promise<void>;
  spectate: (company_id: string | null) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  resetPasswordConfirm: (token: string, password: string) => Promise<void>;
  refresh: (refresh?: boolean) => Promise<void>;
  updateProfile: (values: UpdateGeneralUserParams) => Promise<void>;
  updateProfileLocally: (values: { has_otp: boolean }) => Promise<void>;
}

interface AuthContextState extends AuthProviderState, AuthContextHandlers {
  method: "old" | "jwt";
}

const AuthContext = createContext<AuthContextState>({
  ...initialState,
  method: "old",
  login: () => Promise.resolve({ state: "ok" }),
  handleLoginResponse: () => Promise.resolve({ state: "ok" }),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  pickRole: () => Promise.resolve(),
  spectate: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  resetPasswordConfirm: () => Promise.resolve(),
  refresh: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  updateProfileLocally: () => Promise.resolve(),
});

interface AuthContextProps {
  children: ReactNode;
}

function AuthProvider({ children }: AuthContextProps) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useAuthInitialize(dispatch);
  const {
    spectate,
    pickRole,
    refresh,
    login,
    handleLoginResponse,
    register,
    resetPassword,
    resetPasswordConfirm,
    updateProfile,
    updateProfileLocally,
    logout,
  } = useAuthHandlers(dispatch);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        handleLoginResponse,
        logout,
        refresh,
        register,
        resetPassword,
        resetPasswordConfirm,
        updateProfile,
        updateProfileLocally,
        spectate,
        pickRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

import { useTranslation } from "react-i18next";
import { enUS as coreEnUS, jaJP as coreJaJP } from "@mui/material/locale";
import { enUS, jaJP } from "@mui/x-data-grid";
import { useCallback } from "react";

export const LANGS = [
  {
    label: "English",
    value: "en",
    systemValue: coreEnUS,
    dataGridLocale: enUS,
    icon: "/static/icons/gb.svg",
  },
  {
    label: "Japanese",
    value: "jp",
    systemValue: coreJaJP,
    dataGridLocale: jaJP,

    icon: "/static/icons/jp.svg",
  },
] as const;

export type LangsType = typeof LANGS;

export function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem("i18nextLng");
  const currentLang =
    LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const onChangeLang = useCallback(
    (newLang: string) => {
      i18n.changeLanguage(newLang);
    },
    [i18n]
  );

  return {
    onChangeLang,
    translate,
    currentLang,
    allLang: LANGS,
  };
}

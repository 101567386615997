import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { Attack } from 'api';

// ----------------------------------------------------------------------

interface AttacksState {
  isLoading: boolean;
  error: boolean;
  pastAttacks: Attack[] | null;
  ongoingAttacks: Attack[] | null;
  stats: Attack[] | null;
  pendingAttack: Attack | null;
}

const initialState: AttacksState = {
  isLoading: false,
  error: false,
  pastAttacks: null,
  ongoingAttacks: null,
  stats: null,
  pendingAttack: null,
};

const slice = createSlice({
  name: 'attacks',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PAST ATTACKS
    getPastAttacksSuccess(state, action) {
      state.isLoading = false;
      state.pastAttacks = action.payload;
    },

    // GET ONGOING ATTACKS
    getOngoingAttacksSuccess(state, action) {
      state.isLoading = false;
      state.ongoingAttacks = action.payload;
    },

    // GET PENDING ATTACK
    getPendingAttackSuccess(state, action) {
      state.isLoading = false;
      state.pendingAttack = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getOngoingAttacks(args?: { companyId: string }) {
  const { companyId } = args || {};

  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/attacks/ongoing_attacks', {
        company_id: companyId,
      });
      dispatch(slice.actions.getOngoingAttacksSuccess(response.data.attacks));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPendingAttack() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/attacks/get_survey_attack');
      dispatch(slice.actions.getPendingAttackSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// i18n
import "./locales/i18n";

// scroll bar
import "simplebar/src/simplebar.css";

// map
import "mapbox-gl/dist/mapbox-gl.css";

// lightbox
import "react-image-lightbox/style.css";

// editor
import "react-quill/dist/quill.snow.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// lazy image
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/object-fit/ls.object-fit";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
// redux
import { persistor } from "./redux/store";
// contexts
import { SettingsProvider } from "./contexts/SettingsContext";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";
// components
import LoadingScreen from "./components/LoadingScreen";

import { AuthProvider } from "./contexts/OldLoginContext";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ContextProvidersWithoutAuth } from "contexts";

// ----------------------------------------------------------------------

ReactDOM.render(
  <SettingsProvider>
    <ContextProvidersWithoutAuth>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </CollapseDrawerProvider>
      </PersistGate>
    </ContextProvidersWithoutAuth>
  </SettingsProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import useAuth from "hooks/useAuth";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocales } from "hooks/useLocales";
import { MIconButton } from "components/@material-extend";
import { HandleLogin } from "./types";
import { Links } from "routes/pathsv2";

export default function useLoginPageHandlers(isAdmin: boolean) {
  const { login } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const [needOTP, setNeedOTP] = useState(false);
  const [mfaMethod, setMfaMethod] = useState(null as string | null);

  const handleLogin: HandleLogin = async (values) => {
    const result = await login(
      values.email,
      values.password,
      values.remember,
      needOTP ? values.otp : null,
      isAdmin
    );

    const { state, mfaMethod } = result;

    if (state === "two_factor_required") {
      setNeedOTP(true);
      setMfaMethod(mfaMethod || null);
      return;
    }

    if (state === "two_fa_setup_not_completed") {
      navigate(Links.dashboard.user.account.mfa);
      return;
    }

    enqueueSnackbar(translate("login.login_success_snackbar"), {
      variant: "success",
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      ),
    });
  };

  return {
    handleLogin,
    needOTP,
    mfaMethod,
  };
}

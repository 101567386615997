// routes
import Router from "./routes";
// theme
import GlobalStyles from "./theme/globalStyles";
// hooks
// import useAuth from './hooks/useAuth';
// components
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import NotistackProvider from "./components/NotistackProvider";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import ThemeLocalization from "./components/ThemeLocalization";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
import { ProgressBarStyle } from "./components/LoadingScreen";
import DemoProvider from "./contexts/DemoContext";

import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(
  "c9e6901e754a32c95c67afcd3719e0f3Tz00NDI4NixFPTE2ODQ5NDEyNzAzNTEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

// ----------------------------------------------------------------------

export default function App() {
  // const { isInitialized } = useAuth();

  return (
    <ThemePrimaryColor>
      <ThemeLocalization>
        <RtlLayout>
          <NotistackProvider>
            <GlobalStyles />
            <ProgressBarStyle />
            <BaseOptionChartStyle />
            <ScrollToTop />
            <DemoProvider>
              <Router />
            </DemoProvider>
          </NotistackProvider>
        </RtlLayout>
      </ThemeLocalization>
    </ThemePrimaryColor>
  );
}

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enLocales from './en.json';
import jpLocales from './jp.json';
import { DotNestedFullKeys } from 'types';

export const defaultNS = 'translations';
export const resources = {
  en: { translations: enLocales },
  jp: { translations: jpLocales },
} as const;

export type I18nKeys = DotNestedFullKeys<(typeof resources)['en']['translations']>;

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') || 'en',
    fallbackLng: 'en',
    debug: false,
    ns: [defaultNS],
    defaultNS,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;

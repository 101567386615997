import { css, styled } from "@mui/material/styles";
import { Box, Stack, Typography, CircularProgress } from "@mui/material";
import { LoginForm } from "./components/loginForm";
import { useLocales } from "../../hooks/useLocales";
import LoginContainer from "../../components/_login/LoginContainer";
import useLoginPageHandlers from "./useLoginPageHandlers";
import useAuth from "../../hooks/useAuth";
import { PickRole } from "./components/PickRole";


// ----------------------------------------------------------------------

const LogoStyle = styled(Box, {
  shouldForwardProp: (prop) => prop === "isAdmin",
})<{ isAdmin: boolean }>`
  ${({ isAdmin }) => css`
    position: absolute;
    background-image: url("/static/brand/logo_only_1024.png");
    filter: ${isAdmin && "contrast(0)"};
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.2;
    width: 75%;
    height: 75%;
    bottom: 0;
    right: 0;
  `}
`;

export default function Login({ isAdmin = false }) {
  const { gotInfo, isAuthenticated, selectedRole } = useAuth();
  const { translate } = useLocales();
  const { handleLogin, needOTP, mfaMethod } = useLoginPageHandlers(isAdmin);

  return (
    <LoginContainer
      isAdmin={isAdmin}
      rootComponent={<LogoStyle isAdmin={isAdmin} />}
    >
      {isAuthenticated && !selectedRole ? (
        <PickRole />
      ) : gotInfo ? (
            <>
      <Box mb={5}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {translate("login.sign_in")}
          </Typography>
        </Stack>
        <Typography sx={{ color: "text.secondary" }}>
          {translate("login.enter_details")}
        </Typography>
      </Box>

      <LoginForm
        handleLogin={handleLogin}
        needOTP={needOTP}
        mfaMethod={mfaMethod || undefined}
      />
      </>
      ) : (
        <Stack direction="row" justifyContent="center">
          <CircularProgress size={150} />
        </Stack>
      )}
    </LoginContainer>
  );
}

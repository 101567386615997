import { useLocation } from 'react-router-dom';
import { Suspense } from 'react';
import LoadingScreen from '../components/LoadingScreen';

export default function Loadable(Component) {
  // eslint-disable-next-line react/display-name
  return (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();
    const isDashboard = pathname.includes('/dashboard');

    return (
      <Suspense
        fallback={
          <LoadingScreen
            sx={{
              ...(!isDashboard && {
                top: 0,
                left: 0,
                width: 1,
                zIndex: 9999,
                position: 'fixed',
              }),
            }}
          />
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };
}

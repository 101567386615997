import { LocalizedCompanyConfig } from "api";
import { AppLocale } from "types";

export const USER_ROLE_KEY = "user_role";

interface Language {
  label: string;
  value: LocalizedCompanyConfig["language"];
}
export const availableLanguages: Language[] = [
  { label: "Japanese", value: "jp" },
  { label: "English", value: "en" },
];

export enum CampaignStatuses {
  ongoing = "ongoing",
  scheduled = "scheduled",
  completed = "completed",
}

export type DateFormats = "date" | "dateWithTime";

type LocalsDateFormatsTypes = Record<AppLocale, Record<DateFormats, string>>;

export const localsDateFormats: LocalsDateFormatsTypes = {
  jp: {
    date: "yyyy/M/d",
    dateWithTime: "yyyy/M/d h:mm aaa",
  },
  en: {
    date: "M/d/yyyy",
    dateWithTime: "M/d/yyyy h:mm aaa",
  },
};

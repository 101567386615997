import { ReactNode } from 'react';

import { CssBaseline, Direction, Palette, PaletteMode } from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import useSettings from '../hooks/useSettings';
import shape from './shape';
import { CustomPalette, palette } from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

interface ThemePalette extends CustomPalette, Pick<Palette, 'mode'> {}

declare module '@mui/material/styles' {
  interface Theme {
    palette: ThemePalette;
  }
}

function getThemeConfig(themeMode: PaletteMode, themeDirection?: Direction) {
  const isLight = themeMode === 'light';

  return {
    palette: { ...palette[themeMode], mode: isLight ? ('light' as const) : ('dark' as const) },
    shape,
    typography,
    breakpoints,
    direction: themeDirection,
    shadows: isLight ? shadows.light : shadows.dark,
    customShadows: isLight ? customShadows.light : customShadows.dark,
  };
}

interface ThemeConfigProps {
  children: ReactNode;
}

export function ThemeConfig({ children }: ThemeConfigProps) {
  const { themeMode, themeDirection } = useSettings();

  const themeOptions = getThemeConfig(themeMode as PaletteMode, themeDirection as Direction);

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
